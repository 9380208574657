import gql from "graphql-tag";
import createRecords from "@/helpers/createRecords";

/* jobs = [{
        mutationKey: mutationKey,
        typeKey: typeKey,
        id: recordID,
        value: updateData
    }];
*
* */
export default async (jobs, that) => {
    const updateRecord = async job => {
        const resolver = job.resolver || 'update' + job.typeKey;
        if (job.id === undefined && job.mutation === undefined && job.where === undefined) {
            //console.error("job-id not found in update job. creating record...");
            delete job.id;
            return new Promise(resolve => {
                resolve(createRecords([job], that))
            });
        } else {
            // noinspection GraphQLSchemaValidation,GraphQLUnresolvedReference
            let apolloConfig = {
                "mutation": job.mutation || gql`mutation ($id: ID!, $item: edit${job.typeKey}Input) {
                ${resolver}(
                input:{
                where: {id:$id}
                data: $item
                }){
                ${job.mutationKey} {
                id
                }
                }
                }`,
                "variables": job.variables || {
                    "id": job.id,
                    "item": job.value
                }
            };

            //no id, need to resolve id
            if (job.id === undefined && job.mutation === undefined) {

                const aql = {
                    query: gql`
                        query competitions($where: JSON!){
                            ${job.mutationKey}s(where:$where){
                                id
                            }
                        }
                    `,
                    variables: {where: job.where},
                    fetchPolicy: 'no-cache'
                };

                await that.$apollo.query(aql).then(x => {
                    apolloConfig.variables.id = x.data[job.mutationKey + 's'][0].id;
                }, (er) => {
                    console.error(er);
                });
            }

            if (job.type === 'single') {
                apolloConfig = {
                    mutation: gql`mutation ($item: edit${job.typeKey}Input) {
                    update${job.typeKey}(
                    input:{
                    data: $item
                    }){
                    ${job.mutationKey} {
                    id
                              }
                            }
                    }`,
                    variables: {
                        item: job.value
                    }
                };
            }

            return new Promise(resolve => {
                resolve(
                    that.$apollo.mutate(apolloConfig)
                );
            });
        }

    }


    return Promise.all(
        jobs.map((job) => {
                try {
                    return updateRecord(job)
                } catch (er) {
                    return er;
                }
            }
        )
    );

}