import gql from "graphql-tag";
import helpers from "@/helpers/functions";

/*
 jobs = [{
   typeKey: typeKey,
   list: listname,
   value: createData
 }];
*
* */

export default (jobs, that) => {
    const failed = (er, info) => {
        er = {...er, ...info};
        that.failed(er);
    }
    const createRecord = async (job) => {
        if (job.list === "user") {
            //get roleID
            let roleID;

            await that.$apollo.query({query: gql`query{roles{id name}}`})
                .then(x => {
                    const roles = helpers.findValInObj(x, "roles")[0];
                    roles.forEach(r => {
                        if (r.name === 'verein')
                            roleID = r.id;
                    });
                }, (er) => {
                    failed(er, {function: "createRecord", job: job})
                });

            /* await HTTP.get(process.env.VUE_APP_STRAPI_API_URL+"/users-permissions/roles/").then(x=> {
               //console.log(x);
               const roles = helpers.findValInObj(x,"roles")[0];
               roles.forEach(r => {
                 if (r.name === 'verein')
                   roleID = r.id;
               });
             });*/

            job.value = {
                ...job.value,
                ...{
                    password: Math.random().toString(36).slice(-8),
                    role: roleID,
                    username: that.editedItem.clubname.replace(/[^\w\s]/gi, ''),
                    golfvereine: that.editedItem.id
                }
            };
        }
        return new Promise(resolve => {
            resolve(
                that.$apollo.mutate({
                    mutation: gql`mutation ($item: ${job.typeKey}Input) {
                    create${job.typeKey}(
                    input:{
                        data: $item
                        }){
                            ${job.list} {
                                id
                            }
                        }
                    }`,
                    variables: {
                        item: job.value
                    }
                })
            );
        });
    }


    return Promise.all(
        jobs.map((job) => {
                try {
                    return createRecord(job)
                } catch (er) {
                    return er;
                }
            }
        )
    );

}